@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

.pu-details-downtimes {
  .dt-listings {
    .list-heading {
      margin-bottom: 0.5rem;
    }
    .dt-item {
      padding-top: 0.25rem;
      background-color: var(--color-element-layer2);
      border-radius: var(--border-radius-sm);
    }
    .list,
    .no-list {
      margin-bottom: 1rem;
    }
    .no-list {
      color: var(--color-text-subtle-theme);
      background-color: var(--color-element-layer2);
    }
  }
}

.downtime-container {
  @media ($wx-sm-min) {
    display: flex;

    .dt-unplanned {
      width: calc((100% - var(--box-padding-dashboard)) / 2);

      &:first-child {
        margin-right: var(--box-padding-dashboard);
      }
    }
  }

  @media ($wx-md-min) {
    display: block;

    .dt-unplanned {
      width: 100%;

      &:first-child {
        margin-right: 0;
      }
    }
  }
}

.list .dt-item {
  display: flex;
  align-items: center;

  &::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    margin-right: 8px;
    border-radius: 50%;
    flex-shrink: 0;
  }

  &.item-unplanned {
    &::before {
      background-color: var(--color-justifiedDowntime);
    }
  }
  &.item-unplanned-accessible {
    &::before {
      background-color: var(--color-justifiedDowntimeAccessible);
    }
  }

  &.item-planned {
    &::before {
      background-color: var(--color-plannedDowntime);
    }
  }
  &.item-planned-accessible {
    &::before {
      background-color: var(--color-plannedDowntimeAccessible);
    }
  }
}

.to-justify-chip::before {
  display: none;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
