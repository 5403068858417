@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// override dark background in the date picker :(
.v-picker.v-card {
  background-color: transparent !important;

  .v-picker__body {
    background-color: transparent !important;
  }
}
