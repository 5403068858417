@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

::v-deep .v-navigation-drawer__content {
  display: flex !important;
  flex-direction: column;
  align-items: center !important;
  height: 100%;
}

.breadcrumb-item {
  cursor: pointer;
}
